interface IndexableMf {
  NOT_FOUND: string;
  MATI: string;
  RECEIVED_REQUEST: string;
  STEPPER: string;

  [key: string]: string;
}

export const MFs: IndexableMf = {
  NOT_FOUND: `https://assets.creditas.com/mx-notfound-mf/${process.env.REACT_APP_BUILD_ENV}/creditasmx-notfound-mf.js`,
  MATI: `https://assets.creditas.com/mx-mati-verification-mf/${process.env.REACT_APP_BUILD_ENV}/mx-mati-verification-mf.js`,
  RECEIVED_REQUEST: `https://assets.creditas.com/mx-received-request-mf/${process.env.REACT_APP_BUILD_ENV}/mx-received-request-mf.js`,
  STEPPER: `https://assets.creditas.com/mx-stepper-mf/${process.env.REACT_APP_BUILD_ENV}/mx-stepper-mf.js`,
  PRECALIFICATION: `https://assets.creditas.com/mx-precalification-mf/${process.env.REACT_APP_BUILD_ENV}/mx-precalification-mf.js`,
  REJECTED: `https://assets.creditas.com/mx-rejected-page-mf/${process.env.REACT_APP_BUILD_ENV}/mx-rejected-page-mf.js`,
};

Object.keys(MFs).forEach((key) => {
  MFs[key] = MFs[key] + `?v=${Math.random()}`;
});
